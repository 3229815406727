import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { SectionTitle } from "StaticPages/Components/Top/SectionTitle";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { Box } from "@mui/system";
import { Hidden } from "@mui/material";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title={t("VISUALIZについて | VISUALIZ INC")} />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const { t } = useTranslation();
    return <>
        <main>
            <PageSummary
                title={t("VISUALIZについて")}
                description={<>
                    {t("私たちが考える私たち自身の姿、")}<Hidden mdUp><br /></Hidden>
                    {t("そして向かうべき場所はどこか。")}<br />
                    {t("サービスの根本にある")}<Hidden mdUp><br /></Hidden>
                    {t("理念・想いをヴィジョンとともに。")}
                </>}
                subTitle={t("Philosophy")}
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                src={require("../../../static/images/Saas_top.jpg").default}
            />

            <SectionTitle
                title={t("Philosophy")}
            />

            <SectionTitle2
                title={t("なぜ「VISUALIZ」なのか")}
            />
            <Box
                mt={{ xs: 3, sm: 4, md: 6 }}
                px={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionText1>
                    {t("VISUALIZの前⾝であるBuddys Creative Officeは、僕らを必要としてくれる企業や団体・グループの相棒のような存在でありたいとスタートした。それぞれの会社が抱える問題や悩 みを共有し、解決法を共に考え、突破⼝を創造する。それが僕らの使命だと考えていた。")}
                </SectionText1>
                <SectionText1>
                    {t("インターネットが世界を覆い尽くし、変⾰していくエネルギーを最⼤限に活⽤して、さまざまなチャレンジ＆クリエイティブを積み重ねた。バディとして信頼してくれた、たくさんの想いに応えるために。")}
                </SectionText1>
                <SectionText1>
                    {t("Web業を中⼼にしたバディーズ時代を経て僕の中に湧き上がってきたもの…それは「⾒えないものをカタチにしたい」「⾒えないものを⾒えるように(＝visualise)したい」という強い想いだった。")}
                </SectionText1>
            </Box>

            <Box pt={{
                xs: 4,
                sm: 6,
                md: 8
            }} />

            <SectionTitle
                title={t("Vision")}
            />
            <SectionTitle2
                title={t("ダイバーシティを地⽅から")}
            />
            <Box
                mt={{ xs: 3, sm: 4, md: 6 }}
                px={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionText1>
                    {t("VISUALIZのさまざまな企業・社会が抱える問題を解決するには、テクノロジーの⼒だけでは限界がある。熱い想いを持った⼈間が、いつも未来の新しい扉をこじ開けてきた。前⾝であるBuddys Creative Officeは、僕らを必要としてくれる企業や団体・グループの相棒のような存在でありたいとスタートした。それぞれの会社が抱える問題や悩 みを共有し、解決法を共に考え、突破⼝を創造する。それが僕らの使命だと考えていた。")}
                </SectionText1>
                <SectionText1>
                    {t("僕たちVISUALIZも例外ではない。")}
                </SectionText1>
                <SectionText1>
                    {t("難題に直⾯する度、その壁を崩してきたのは僕たちの情熱と固いチームワークだ。バラエティに富んだ経歴を持つスタッフ達が⽇々、奮闘している。")}
                </SectionText1>
                <SectionText1>
                    {t("さらにこのチームの熱量を⾼めていくために必要だと考えていること。それがダイバーシティだ。企業におけるダイバーシティとは、国籍・性別・年齢などを問わず、多様な⼈材を登⽤することで、⽣産性の向上と社員個々⼈の幸せの追求を両⽴させようというもの。昨今、世間でも声⾼に叫ばれているこのムーブメントに、VISUALIZも⼤いにのっかっていきたい。さまざまな⾔語が⾶び交い、活気溢れる空間でたくさんのものを⽣み出していくのだ。")}
                </SectionText1>
                <SectionText1>
                    {t("アジア新興国へのサテライトオフィス開設なども念頭に、多様な⼈材が集まりやすい中央都市ではなく地⽅でも、ダイバーシティを実現していきたい。")}
                </SectionText1>
            </Box>

            <Box pt={2} />
            <SectionTitle2
                title={t("⽬指すのはタイムダラー")}
            />
            <Box
                mt={{ xs: 3, sm: 4, md: 6 }}
                px={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionText1>
                    {t("乱暴な⾔い⽅かもしれないが、多くの企業は”いかに⼈々の時間を⾃社のサービスや商品が奪えるか”に⼼⾎を注いでいる。 Creative Officeは、僕らを必要としてくれる企業や団体・グループの相棒のような存在でありたいとスタートした。それぞれの会社が抱える問題や悩 みを共有し、解決法を共に考え、突破⼝を創造する。それが僕らの使命だと考えていた。")}
                </SectionText1>
                <SectionText1>
                    {t("1分でも1秒でも注⽬される時間を延ばすこと。それが利益につながるからだ。容易に⼈々の時間を奪っていくモバイルデバイスがここまで浸透すると、同業種を超えてあらゆる業界が競合先として⽴ち現れてくる。情報洪⽔の中では⾃社のポジションを確⽴するだけでも、相当のコストや知恵と戦略、Luckまでもが必要になるようになってきた。⼈々の可処分所得ならぬ可処分時間を奪い合う社会の到来だ。")}
                </SectionText1>
                <SectionText1>
                    {t("この時代の潮流に、僕たちは潔く⽩旗を掲げようと思う。奪い合いには参加しない。正直に⾔うと参加できないのだ。マンパワーをとっても、マネーパワーをとっても、とてもじゃないけれど僕らに勝ち⽬はない。")}
                </SectionText1>
                <SectionText1>
                    {t("それならどうするか。僕らは時間を奪い合うのではなく、創出する企業になろうと思う。みんなの可処分時間を延ばすサービスを提供するのだ。")}
                </SectionText1>
                <SectionText1>
                    {t("⼈がしなくてもいい仕事、効率化できる⽇常的な作業はたくさんある。⾶躍的なテクノロジーの発展により、改善の可能性は無限⼤だ。VISUALIZはここに賭けていきたい。さまざまなテクノロジーの⼒を使って、ヒトが⼈として幸せに過ごせる時間を増やす。それが僕らの使命だ。")}
                </SectionText1>
                <SectionText1>
                    {t("仕事のために⼤切な時間や命を削る毎⽇なんて、絶対におかしい。⼦どものため、ローンのため、⽼後のためと今を犠牲にする必要なんてどこにもない。")}
                </SectionText1>
                <SectionText1>
                    {t("社会の中で僕らはとってもちっぽけな存在だ。⻘臭いただの理想論だと笑われても、掲げた使命を胸に、これからも奪い合いの社会に挑み続ける。余剰時間を⽣み出し続けることで、僕らは新たな価値を創出していくのだ。")}
                </SectionText1>
                <SectionText1>
                    {t("ひと昔前は余剰蓄積の象徴として、オイルダラーという⾔葉が世界を席巻した。これからの時代は幸せの尺度の⼀つとして、タイムダラーを考えるべきだ。")}
                </SectionText1>
                <SectionText1>
                    {t("幸福の証として、クリーンなタイムダラーをVISUALIZは追い求める。 ")}
                </SectionText1>
            </Box>

            <Box
                py={{ xs: 3, sm: 4, md: 6 }}
            />
        </main>
    </>;
};